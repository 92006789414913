.primary {
    @apply rounded-md bg-lavender-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lavender-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600
}

.default {
    @apply text-sm font-semibold leading-6 hover:text-lavender-500 text-gray-900
}

.default.active {
    @apply text-lavender-600
}

.navmobile {
    @apply -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50
}

.navmobile.active {
    @apply bg-gray-100
}

.icon {
    @apply text-gray-400 hover:text-gray-600
}